import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './styles/colors';
import routes from './navigator/routes';
import { useRoutes, useLocation } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4'

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const App = () => {
  const isLoggedIn = Cookies.get('idToken') ? true : false;
  const routing = useRoutes(routes(isLoggedIn));
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', 
      page: window.location.pathname + window.location.search, 
    })
  }, [location]);
  return (
    <>
    <ThemeProvider theme={theme}>
    <AppLayout />
    {routing}
    </ThemeProvider>
    </>
  );
}

export default App;
