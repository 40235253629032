import styled from "styled-components";
import { Title2, Headline } from "../../../styles/typography";
import Button from "../../../components/Button";
import { FiX } from 'react-icons/fi';
import LogoutButton from "../../user/LogoutButton";

const ModalWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 16px 32px;
isolation: isolate;
row-gap: 16px;
position: relative;
max-width:500px;
@media screen and (max-width: 600px) {
    padding: 16px 24px;
    width:320px;
    margin-bottom: 304px;
};
background: #FFFFFF;
border-radius: 8px;
`;

const ModalHeaderWrapper = styled.div`
display:grid;
grid-auto-flow:column;
grid-auto-columns: 12fr 1fr;
@media screen and (max-width: 600px) {
    grid-auto-columns: 12fr 2fr;

} ;
svg{
    color:rgb(${props => props.theme.colors.neutral200});
    height:20px;
    width:20px;
    padding:8px;
    position: relative;
    left: 80%;
    bottom: 45%;
    @media screen and (max-width: 600px) {
        left: 75%;
        bottom: 30%;
    }
}
`;

const ModalHeader = styled(Title2)`
color: #0B0C0F;
`;

const ModalDescription = styled(Headline)`
color: #2E313E;
`;
const ModalButtonWrapper = styled.div`
display: flex;
flex-direction: row;
column-gap: 12px;
margin-top: 12px;
@media screen and (max-width: 600px) {
    flex-direction: column;
    row-gap: 16px;
};
`;
const ModalSaveButton = styled(Button)`
background: #0B0C0F;
`;
const ModalCancelButton = styled(Button)`
background: #F3F5F7;
`;

const BookMemoryModal = ({closeModal, reSubmitSelection, isLogoutModal}) => {
    return (
        <ModalWrapper>
            <ModalHeaderWrapper>
                <ModalHeader>
                You have unsaved changes
                </ModalHeader>
                <FiX onClick={closeModal} />
            </ModalHeaderWrapper>
            <ModalDescription>
            Save and re-submit your selection for this book?
            </ModalDescription>
            <ModalButtonWrapper>
                <ModalSaveButton type="primary" onClick={reSubmitSelection}>Re-submit selection</ModalSaveButton>
                {isLogoutModal ? <LogoutButton minWidth={'212px'} /> :
                    <ModalCancelButton type="secondary" onClick={closeModal}>Exit</ModalCancelButton>
                }
            </ModalButtonWrapper>
        </ModalWrapper>
    )
};

export default BookMemoryModal