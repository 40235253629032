import React from "react";
import styled from "styled-components";
import SampleBook from "./SampleBook";
import BooKImage from "./BookImage";
import LogoutButton from "../../user/LogoutButton";
import Button from '../../../components/Button';
import theme from '../../../styles/colors';
import {
    Headline,
    Title1,
  } from '../../../styles/typography';

const ExistMemoryContainer = styled.div`
  height: 100vh;
  width: 0vw;
  overflow: hidden;
  transform: translateY(-50%);
  transition: 0.7s;

`;

const ExContainer = styled.div`
`;

const ExistMemoryMainContainer = styled.div`
margin: auto;
  width: 752px;
  @media only screen and (max-width: 599px) {
    top: 25%;
  }
`;

const ExistMemoryBookCard = styled.div`
  width: 752px;
  @media only screen and (max-width: 999px) {
    padding: 0px 24px;
    width: 552px;
  }
  @media only screen and (max-width: 599px) {
    padding: 0px 24px;
    width: 100vw;
    box-sizing: border-box;
  }
`;

const CardHeader = styled.div`
  margin: ${(props) => props.margin};
  @media screen and (max-width:599px){
    text-align: start;
    padding: 0px 24px;
  }
`;
const CardTitle = styled(Title1)`
  color: ${theme.buttonPrimaryBg};
  font-size: 30px;
  line-height: 125%;
  
`;

const CardDescription = styled(Headline)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;
const TyCardDescription = styled.div`
width: auto;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 23px;
display: flex;
align-items: center;
margin-top: 20px;
color: ${`rgb(${theme.colors.neutral400})`};
`;

const TyCardFooter = styled.div`
display: flex;
flex-direction: row;
gap: 12px;
@media screen and (max-width: 399px) {
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width:600px){
padding: 32px 0px 24px 0px;
}
`;

const TyCardButton = styled(Button)`
box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 12px 16px;
width: 148px;
height: 44px;
background: #F3F5F7;
border: 1px solid #E2E4E9;
border-radius: 1000px;
@media screen and (max-width:599px){
  margin-left: 24px;
}
`;
const ExistMemory = ({totalMemories, closeWindow}, ref) => {
    const minMemoryExist = 10;

    return (
        <>
        <ExistMemoryContainer ref={ref}>
            <BooKImage />

            <ExContainer>
              <ExistMemoryMainContainer>
                <ExistMemoryBookCard>
                  <CardHeader>
                    <CardTitle>You are {minMemoryExist - totalMemories} {minMemoryExist - totalMemories === 1 ? 'memory' : 'memories'} away from a book</CardTitle>
                  </CardHeader>
                  <CardHeader margin="4px 0px 32px 0px">
                    <CardDescription>
                      You need at least 10 memories to create a book.
                    </CardDescription>
                    <TyCardDescription>
                      {'In the meantime, you can view our sample to get an idea of what your book could look like! '}
                      <SampleBook />
                    </TyCardDescription>

                  </CardHeader>
                  <TyCardFooter>
                      <TyCardButton type="secondary" onClick={closeWindow}>Back to MSM</TyCardButton>
                      <LogoutButton />
                    </TyCardFooter>
                </ExistMemoryBookCard>
              </ExistMemoryMainContainer>
            </ExContainer>
        </ExistMemoryContainer>
        </>
    )
}

export default React.forwardRef(ExistMemory);