import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "../../../app/reducers/BookMemorySlice";
import booktitle from '../../../images/booktitle.svg';

const ImageSection = styled(Link)``;
const Image = styled.img``;

const BooKImage = () => {
    const dispatch = useDispatch();
    return (
        <ImageSection to={'/'} onClick={() => dispatch(reset())}>
        <Image src={booktitle} />
        </ImageSection>
    )
}

export default BooKImage;