import React from "react";
import styled from "styled-components";

const ViewsampleText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 23px;
cursor: pointer;
text-decoration: underline;
display: contents;
color: #0A4D8F;
white-space:nowrap;
`;

const SampleBook = () => {

    return (
        <>
            <ViewsampleText
                as="a"
                href={process.env.REACT_APP_SAMPLE_BOOK_LINK}
                target="_blank"
                >
                {'View a sample -->'}
            </ViewsampleText>
        </>
    )
}

export default SampleBook;