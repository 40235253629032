import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Button from "../../components/Button";
import booktitle from "../../images/booktitle.svg";
import bookImg from "../../images/bookimage.svg";
import theme from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import { Headline, Caption1, Title1 } from "../../styles/typography";
import SubCard from "./components/SubCard";

const BookEditorContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  /* 
   background-image: url('https://i.ibb.co/4VqFggW/banner.png');
   */
  background-image: url(${bookImg});
  background-size: cover;
  background-position: center;
`;

const ImageSection = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
const Image = styled.img``;

const MainContainer = styled.div`
  position: absolute;
  background-color: ${theme.buttonPrimaryText};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  width: 800px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.7s;
  @media only screen and (max-width: 999px) {
    width: 552px;
  }
  @media only screen and (max-width: 599px) {
    left: calc(50% - 24px);
    margin: auto 24px;
    width: calc(100vw - 24px);
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const BookCard = styled.div`
  margin: 24px 24px 0px 24px;
  padding-bottom: 24px;
  height: 100%;
  transition: 0.7s;
  width: 100%;
  overflow: hidden;
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: ${(props) => props.margin};
`;

const CardTitle = styled(Title1)`
  color: rgb(${(props) => props.theme.colors.neutral600});
  text-align: center;
`;
const CardDescription = styled(Headline)`
  text-align: center;
  color: rgb(${(props) => props.theme.colors.neutral600});
`;

const CardBody = styled.div`
  margin: ${(props) => props.margin};
`;
const MainSubCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  width: 100%;
`;

const CardFooter = styled.div`
  margin-top: 24px;
  position: ${(props) => props.position};
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const CardFooterText = styled(Caption1)`
  text-align: center;
  color: rgb(${(props) => props.theme.colors.neutral200})
`;

const CardButton = styled(Button)`
  bottom: ${(props) => props.bottom};
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

const BookCreation = () => {
  const maxWidth_600 = useMediaQuery({ maxWidth: 600 });
  const navigate = useNavigate();
  const bookRef = useRef(null);
  const MainContainerRef = useRef(null);
  const bookCardData = [
    {
      title: 1,
      heading: "Select your memories",
      description:
        "Choose the published memories you’d like to include in your book.",
    },
    {
      title: 2,
      heading: "Plan the details",
      description: "We’ll reach out to gather all the details for your book.",
    },
    {
      title: 3,
      heading: "Get your quote",
      description: "Confirm all the details before we send your book to print.",
    },
  ];

  const openLogin = () => {
    navigate("/login-redirect");
  };

  return (
    <>
      <BookEditorContainer>
        <ImageSection>
          <Image src={booktitle} />
        </ImageSection>
        <MainContainer ref={MainContainerRef}>
          <Container>
            <BookCard ref={bookRef}>
              <CardHeader>
                <CardTitle>
                  Create a Memory Book
                </CardTitle>
              </CardHeader>
              <CardHeader margin="8px auto auto auto">
                <CardDescription>
                  Stitch your memories from My Stories Matter together into a
                  physical keepsake, to give as a gift or to keep.
                </CardDescription>
              </CardHeader>
              <CardBody margin="14px 0px">
                {!maxWidth_600 && (
                  <MainSubCard>
                    {bookCardData.map((card, ind) => {
                      return (
                        <SubCard
                          key={Math.random() + ind}
                          title={card.title}
                          heading={card.heading}
                          description={card.description}
                        />
                      );
                    })}
                  </MainSubCard>
                )}
              </CardBody>
              <CardFooter position="relative">
                <CardButton
                  type="primary"
                  onClick={openLogin}
                >
                  Get started <FiArrowRight />
                </CardButton>
                <CardFooterText>
                  {`*Price is based on number of pages and printing options and does not include shipping & taxes.`}
                </CardFooterText>
              </CardFooter>
            </BookCard>
          </Container>
        </MainContainer>
      </BookEditorContainer>
    </>
  );
};

export default BookCreation;
