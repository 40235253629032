import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      position: 'fixed',
      borderRadius: '8px',
    },
  };

  Modal.setAppElement('#root');

  Modal.defaultStyles.overlay.backgroundColor = 'rgb(191, 191, 191, 0.3)';
    const ModalPopUp = ({ isOpen, children }) => {
    return (
            <Modal isOpen={isOpen} style={customStyles} contentLabel="Example Modal">
            {children}
            </Modal>
        );
    };
    export default ModalPopUp;