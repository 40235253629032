import React from 'react';
import styled from 'styled-components';
import FiCheck from '../images/FiCheck.svg';
// import '../styles/checkbox.css';

const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
&:hover input ~ span {
  background-color: #e2e4e9;
}

 input:checked ~ span {
  background-color: #2f2f3d;
}
input:checked ~ span:after {
  display: block;
}

span::after {
  background-image: url(${FiCheck});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;

  /* left: 8.5px;
  top: 4.1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
}
`;

const StyledSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: #e2e4e9;
&::after {
  content: '';
  position: absolute;
  display: none;
}
`;

const StyledInput = styled.input``;


const Checkbox = ({ selectItem, checked }) => {
  return (
    <StyledLabel>
      <StyledInput
        type="checkbox"
        checked={checked}
        onChange={() => {
          selectItem();
        }}
      />
      <StyledSpan></StyledSpan>
    </StyledLabel>
  );
};

export default Checkbox;
