import React from "react";
import styled from "styled-components";
import BooKImage from "./BookImage";
import LogoutButton from "../../user/LogoutButton";
import Button from '../../../components/Button';
import theme from '../../../styles/colors';
import {
    Headline,
    Title1,
    Caption1
  } from '../../../styles/typography';

const ThankYouContainer = styled.div`
  transform: translateY(-50%);
  height: 100vh;
  width: 0vw;
  overflow: hidden;
  transition: 0.7s;
`;

const ThankYouMainContainer = styled.div`
margin: auto;
  width: 752px;
  @media only screen and (max-width: 599px) {
    top: 25%;
  }
`;

const ThankYouBookCard = styled.div`
  width: 752px;
  @media only screen and (max-width: 999px) {
    padding: 0px 24px;
    width: 552px;
  }
  @media only screen and (max-width: 599px) {
    padding: 0px 24px;
    width: 100vw;
    box-sizing: border-box;
  }
`;

const CardHeader = styled.div`
  margin: ${(props) => props.margin};
  @media screen and (max-width:599px){
    text-align: start;
    padding: 0px 24px;
  }
`;
const CardTitle = styled(Title1)`
  color: ${theme.buttonPrimaryBg};
  font-size: 30px;
  line-height: 125%;
  
`;

const CardDescription = styled(Headline)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;
const TyCardDescription = styled.div`
width: auto;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 23px;
display: flex;
align-items: center;
margin-top: 20px;
color: ${`rgb(${theme.colors.neutral400})`};
`;

const TyCardFooter = styled.div`
display: flex;
flex-direction: row;
gap: 12px;
@media screen and (max-width: 599px) {
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width:600px){
padding: 32px 0px 24px 0px;
}
`;

const TyCardButton = styled(Button)`
box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 12px 16px;
width: 148px;
height: 44px;
background: #F3F5F7;
border: 1px solid #E2E4E9;
border-radius: 1000px;
@media screen and (max-width:599px){
  width: 90%
}
`
const TyContainer = styled.div`
`;
const TyFooter = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
position: absolute;
bottom: 0%;
width: 100%;
margin-bottom: 24px;
`;
const TyFooterText = styled(Caption1)`
color: #4C5367;
display: flex;
flex-direction: row;
gap: 4px;
@media only screen and (max-width: 599px) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
`
const TyFooterLink = styled.span`
color: #4C5367;
`
const FormLink = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 23px;
cursor: pointer;
text-decoration: underline;
display: contents;
color: #0A4D8F;
`;

const ThankYouComponent = ({closeWindow}, ref) => {
    
    return (
        <ThankYouContainer ref={ref}>
        <BooKImage />

        <TyContainer>
          <ThankYouMainContainer>
            <ThankYouBookCard>
              <CardHeader>
                <CardTitle>Thank you!</CardTitle>
              </CardHeader>
              <CardHeader margin="4px 0px 32px 0px">
                <CardDescription>
                  Our designer will reach out via email to you shortly with a
                  sample of what your book will look like. From there, you can
                  choose to purchase it and we will ship it to you.
                </CardDescription>

                <TyCardDescription>
                {`In the meantime, continue the fun designing your book by filling out the `} 
                <FormLink
                as="a"
                href={`https://us14.list-manage.com/survey?u=63d96d1bc9c76f87408c287f7&id=067f5f4579&attribution=false`}
                target="_blank"
                >
                {'book details form. '}
                </FormLink>
                {`You’ll be able to choose your title, cover preferences, and more!`}
                </TyCardDescription>

                <TyCardDescription>
                  We look forward to creating a book with you!
                </TyCardDescription>
              </CardHeader>
              <TyCardFooter>
                <TyCardButton type="secondary" onClick={closeWindow}>Back to MSM</TyCardButton>
                <LogoutButton />
              </TyCardFooter>
            </ThankYouBookCard>
          </ThankYouMainContainer>
          <TyFooter>
            <TyFooterText>
            {'Have an idea for our new book feature?'} 
            <TyFooterLink
            as="a"
            href={`https://my-stories-matter-book.canny.io/feature-requests`}
            target="_blank"
            >
           {'Add it here!'}
            </TyFooterLink>
            </TyFooterText>
          </TyFooter>
        </TyContainer>
      </ThankYouContainer>
    )
}

export default React.forwardRef(ThankYouComponent);