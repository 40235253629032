import styled from "styled-components";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { reset } from "../../app/reducers/BookMemorySlice";
import Button from '../../components/Button';
import { getDomain } from "../../util/functions";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../app-api/api";
import isBrowser from "../../util/DetectBrowser";

const StyledLogoutButton = styled(Button)`
  min-width: ${props => props.minWidth ? props.minWidth : 'fit-content'};
  background:rgb(${props => props.theme.colors.error100});
  border: 1px solid rgb(${props => props.theme.colors.error200});
  color:rgb(${props => props.theme.colors.error400});
  align-self: flex-start;
  @media screen and (max-width:599px){
    align-self: center;
    min-width: ${props => props.minWidth ? '272px' : '195px'};
    width: ${props => props.minWidth ? '100%' : '90%'};
  }
`;

const LogoutButton = ({minWidth}) => {
  const navigate = useNavigate();
  const domain = getDomain();
  const dispatch = useDispatch();
  const logoutUser = () => {
    Logout();
    dispatch(reset());
    if (isBrowser) {
      Cookies.remove('uid', {path: '/', domain: domain});
      Cookies.remove('idToken', {path: '/', domain: domain});
      localStorage.clear();
      navigate('/');
    }
  }
  return (
    <>
    <StyledLogoutButton minWidth={minWidth} type="secondary" onClick={logoutUser}>Log out</StyledLogoutButton>
    </>
  )
};

export default LogoutButton;