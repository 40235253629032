import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import theme from '../../styles/colors';
import BooKImage from './components/BookImage';
import ThankYouComponent from './components/ThankYouComponent';
import ExistMemory from './components/ExistMemory';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBookMemories,
  addBookMemories,
  getBookMemories,
  updateBookMemories,
  getBookList,
  selectBookMemories,
  selectBookMemoriesStatus,
  selectPage,
  fetchCurrentUserBookMemories,
  pageCounter,
  selectHasMoreData,
  selectHasBooks,
  selectTotalMemories,
  setBookMemoriesState,
  selectOrderBy,
  selectSortBy,
  selectSelectedMemories,
  selectBookUpdateStatus,
  selectBookMemoriesAdded,
  selectHasBookMemories
} from '../../app/reducers/BookMemorySlice';
import { DateTime } from 'luxon';
import {
  Bold,
  Caption1,
  Footnote,
  Headline,
  Subhead,
  Title1,
} from '../../styles/typography';
import { Dropdown } from '../../components/Dropdown';
import Checkbox from '../../components/Checkbox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SpinnerCircular } from 'spinners-react';
import './book.css';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ModalPopUp from '../../components/ModalPopUp';
import BookMemoryModal from './components/SaveBookMemoriesModal';
import CardBody from './components/CardBodyCss';
import SampleBook from './components/SampleBook';
import LogoutButton from "../user/LogoutButton";
// import { BsArrowRight } from 'react-icons/bs'

const BookContainer = styled.div`
  display: flex;
`;

const BookEditorContainer = styled.div`
  width: 100%;
  transition: 0.7s;
`;

const MainContainer = styled.div`
  margin: auto;
  width: 752px;
  @media only screen and (max-width: 600px) {
    width: 100vw;
    box-sizing: border-box;
} 
  @media only screen and (min-width:320px) and (max-width: 599px) {
    box-sizing: border-box;
  }
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    overflow: hidden;
    width: 552px;
  }
`;

const BookCard = styled.div``;

const BookEditorNote = styled(Caption1)`
padding-top: 8px;
width: 100%;
height: 72px;
display: flex;
align-items: center;
letter-spacing: -0.05em;
color: ${`rgb(${theme.colors.neutral200})`};
@media only screen and (max-width:599px){
margin-top:8px;
padding:0px 24px;
display:flex;
align-items:flex-start;
width: 90%;
height: auto;
}
@media only screen and (min-width: 600px) and (max-width:1000px){
width: 100%;
height: 90px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 125%;
display: flex;
align-items: center;
letter-spacing: -0.05em;
color: ${`rgb(${theme.colors.neutral200})`};
}
`
const CardHeader = styled.div`
  margin: ${(props) => props.margin};
  @media screen and (max-width:599px){
    text-align: start;
    padding: 0px 24px;
  }
`;
const CardTitle = styled(Title1)`
  color: ${theme.buttonPrimaryBg};
  font-size: 30px;
  line-height: 125%;
  
`;

const CardDescription = styled(Headline)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;

const HighlightCardDes = styled(Bold)``;

const CardList = styled(InfiniteScroll)`
`;

const CardRow = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
  margin-bottom: 8px;
`;

const CheckContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 4px;
  width: 652px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(7, 53, 98, 0.05),
    0px 2px 2px rgba(7, 53, 98, 0.05);
  border-radius: 8px;
`;
const ContentTitle = styled(Subhead)`
  font-size: 17px;
  line-height: 110%;
  color: ${`rgb(${theme.colors.neutral600})`};
  word-break: break-word;
`;
const ContentDetails = styled(Caption1)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;
const Selection = styled.div`
  margin: 32px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dropdown-body {
      z-index: 1;
  }
  @media only screen and (max-width: 599px) {
    display: block;
    margin-top: 17.5px;
  }
`;

const SelectMemory = styled.div`
  @media only screen and (max-width: 599px) {
    text-align: center;
    margin-bottom: 2px;
  }
`;
const NoOfMemory = styled(Footnote)`
  line-height: 125%;
  color: ${`rgb(${theme.colors.neutral200})`};
`;

const CardFooter = styled.div` 
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 12px;
padding: 32px 0px 24px 0px;
@media only screen and (max-width: 599px) {
flex-direction: column;
padding: 32px 0px 24px 0px;
}
`

const CardFooterText = styled(Caption1)`
color: ${`rgb(${theme.colors.error400})`};
`;

const CardButtonWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 12px;
`;

const CardButton = styled(Button)`
min-width: ${(props) => props.minWidth};
@media only screen and (min-width: 320px) and (max-width: 599px){
  width: 90%;
}
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95vw;
`;

const LoaderText = styled(Headline)`
  position: absolute;
  top: 40%;
  text-align: center;
  @media screen and (max-width:599px){
    top: 38%;
  }
`;

const DropDownContainer = styled.div`
display: flex;
flex-direction: column;
`;
const DropDownLabel = styled(Caption1)`
color: #4c5367;
padding: 0 8px;
@media only screen and (max-width: 599px) {
    padding: 0px 32px;
}
`;
const LogoutButtonWrapper = styled.div`
display: flex;
justify-content: center;
@media only screen and (min-width: 320px) and (max-width: 599px){
  width: 100%;
}
`;

const BookEditor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bookId = searchParams.get('bookId');
  const selectedMemories = useSelector(selectSelectedMemories);
  const bookUpdateStatus = useSelector(selectBookUpdateStatus);
  const bookMemoriesAdded = useSelector(selectBookMemoriesAdded);
  const editorRef = useRef(null);
  const thankuRef = useRef(null);
  const memoryRef = useRef(null);
  const memoryListRef = useRef(null);
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [submitButtonText, setSubmitButtonText] = useState(false);
  const minMemoryExist = 10;
  const page = useSelector(selectPage);
  const bookMemories = useSelector(selectBookMemories);
  const status = useSelector(selectBookMemoriesStatus);
  const hasMoreData = useSelector(selectHasMoreData);
  const hasBooks = useSelector(selectHasBooks);
  const hasMemories = useSelector(selectHasBookMemories);
  const totalMemories = useSelector(selectTotalMemories);
  const navigate = useNavigate();
  const orderBy = useSelector(selectOrderBy);
  const sortBy = useSelector(selectSortBy);
  const uid = Cookies.get('uid');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const obj = {
    params: {
      userId: Cookies.get('uid'),
      pageIndex: page,
      pageSize: 10,
      orderBy: orderBy,
      sortBy: sortBy,
    }
  };
  window.onpopstate = () => {
    // navigate('/app/bookcreation');
  }

  const editorObj = {
    display: 'none',
    width: '0vw',
    transform: `translate(-100%, 0px)`,
  };
  const thankuObj = {
    width: '100vw',
    transform: `translateY(0%)`,
  };
  let ExistObj = {
    width: '100vw',
    transform: `translateY(0%)`,
  }

  const goToThankyouPage = () => {
    Object.keys(editorObj).map((item) => {
      editorRef.current.style[item] = editorObj[item];
    });

    Object.keys(thankuObj).map((item) => {
      thankuRef.current.style[item] = thankuObj[item];
    });
  }

  useEffect(() => {
    setSelectedItem(selectedMemories);
    setIsSelected(selectedMemories?.length);
    if (hasMemories === false) {
      navigate(`/app/booksubmission`);
    }
  }, [selectedMemories]);

  useEffect(() => {
    if (bookId && parseInt(bookId) !== 0) {
      dispatch(getBookMemories({details: {book_id : bookId}}));
    }
    else {
      dispatch(getBookList({ details: { uid: uid } }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (bookId) {
      dispatch(fetchBookMemories(obj));
      dispatch(pageCounter());
    }
  }, [orderBy, bookId])

  useEffect(() => {
    if (!bookId) {
      if (hasBooks === false) {
        navigate(`/app/bookedit?bookId=0`);
      }
      else if (hasBooks === true) {
        navigate('/app/booksubmission');
        // goToThankyouPage();
      }
    }
  }, [hasBooks]);

  useEffect(() => {
     if (bookUpdateStatus === true) {
      closeModal();
      navigate('/app/booksubmission');
     }
  }, [bookUpdateStatus]);

  useEffect(() => {
    if (bookMemoriesAdded === true) {
      if (searchParams.has('bookId')) {
        searchParams.delete('bookId');
        setSearchParams(searchParams);
      }
      goToThankyouPage();
    }
 }, [bookMemoriesAdded]);

  const fetchMoreData = () => {
    dispatch(fetchCurrentUserBookMemories(obj));
    dispatch(pageCounter());
  };

  const handleDropDown = (value) => {
    value.selected = true;
    memoryListRef.current.scrollTop = 0;
    const order = {
      orderBy:  'changed',
      sortBy: 'DESC',
      selectedMemoriesFlag: true,
    }
    if (value.label === 'Alphabetical Order') {
        order.orderBy = 'title';
        order.sortBy = 'ASC';
    }
    else if (value.label === 'Chronological Order') {
        order.orderBy = 'field_memory_date_value';
        order.sortBy =  'ASC';
    }
    else if (value.label === 'Most Recent') {
      order.orderBy = 'changed';
      order.sortBy = 'DESC';
    }
    setTimeout(() => {dispatch(setBookMemoriesState(order));}, 500);
  }

  const submitSelection = () => {
    const obj = {
      details: {
        uid: uid,
        memories: selectedItem
      }
    };
    dispatch(addBookMemories(obj));
  };

  const reSubmitButtonClick = () => {

    if (JSON.stringify(selectedItem) === JSON.stringify(selectedMemories)) {
      navigate('/app/booksubmission');
    }
    else {
      setIsModalOpen(true);
    }
  };

  const reSubmitSelection = () => {
    setIsModalOpen(false);
    const obj = {
      details: {
        uid: uid,
        book_id: bookId,
        memories: selectedItem
      }
    };
    dispatch(updateBookMemories(obj));
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const closeWindow = () => navigate('/mystories-matter');
  const addItem = (index) => {
    toggleCheckbox(index);
    setSelectedItem((previous) => [...previous, index]);
  };
  const removeItem = (index) => {
    toggleCheckbox(index);
    const removeElement = selectedItem?.filter((item) => item !== index);
    setSelectedItem([...removeElement]);
  };

  const findItem = (index) => {
    const isItemAvailable = selectedItem.indexOf(index);
    return isItemAvailable;
  };

  const toggleCheckbox = (index) => {
    findItem(index) === -1
      ? setIsSelected(isSelected + 1)
      : setIsSelected(isSelected - 1);
  };
  const selectItem = (index) => {
    findItem(index) === -1 ? addItem(index) : removeItem(index);
  };

  const logoutButtonWrappedClicked = (e) => {
    if (JSON.stringify(selectedItem) !== JSON.stringify(selectedMemories)) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      setIsModalOpen(true);
      setIsLogoutModal(true);
    }
  }

  const dropDownData = [
    { id: 1, label: 'Most Recent', selected: true },
    { id: 2, label: 'Alphabetical Order', selected: false  },
    { id: 3, label: 'Chronological Order', selected: false  },
  ];

  return (
    <BookContainer>
      {
          ((hasBooks === null || hasBooks === false) && status !== 'idle')  ?
          <>
          <LoaderContainer>
          {
          status === 'loading' ?
            <LoaderText>
            Gathering your memories. This should only take a minute!
            </LoaderText>: ''
          }
            <SpinnerCircular
                            size={48}
                            thickness={200}
                            aria-label="Loading Spinner"
                            speed={100}
                            color={`rgba(${theme.colors.neutral200}, 1)`}
                            secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
                            style={{ justifySelf: 'center', position: 'absolute', left: '45%', top: '45%' }}
                          />
          </LoaderContainer>
          </>
          :
          <>
            <BookEditorContainer ref={editorRef}>
              <BooKImage />

              <MainContainer>
                <BookCard>
                  <CardHeader>
                    <CardTitle>Select memories for your book</CardTitle>
                  </CardHeader>
                  <CardHeader margin="4px 0px 0px 0px">
                    <CardDescription>
                    {'Books need to be at least 24 pages. For the best results, we recommend selecting longer memories. '}
                      <SampleBook />
                    </CardDescription>
                  </CardHeader>
                  <Selection>
                    <SelectMemory>
                      <NoOfMemory>
                        { status === 'idle' ? `${isSelected}/${totalMemories} memories selected` : ''} 
                      </NoOfMemory>
                    </SelectMemory>
                    <DropDownContainer>
                    <DropDownLabel>{'SORT BY'}</DropDownLabel>
                    <Dropdown dropDownCallback={handleDropDown} data={dropDownData} />
                    </DropDownContainer>
                  </Selection>
                  <CardBody
                    padding="24px 24px 0px 24px"
                    className="test_scroll"
                    id="overflowMain"
                    ref={memoryListRef}
                  >
                    <CardList
                      dataLength={bookMemories?.length}
                      next={fetchMoreData}
                      hasMore={hasMoreData}
                      scrollThreshold={0.5}
                      scrollableTarget="overflowMain"
                      endMessage={totalMemories === 0 ? 'No memories found' : ''}
                      loader={
                        <SpinnerCircular
                          size={48}
                          thickness={200}
                          speed={100}
                          color={`rgba(${theme.colors.neutral200}, 1)`}
                          secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
                          style={{ justifySelf: 'center', position: 'relative', left: '45%' }}
                        />
                      }
                    >
                      {bookMemories &&
                        bookMemories?.map((data, ind) => {
                          return (
                            <CardRow key={Math.random() + ind}>
                              <Checkbox
                                name={data.id}
                                checked={findItem(data.nid) === -1 ? false : true}
                                selectItem={() => selectItem(data.nid)}
                              />
                              <CheckContent>
                                <CardHeader>
                                  <ContentTitle>{data.title}</ContentTitle>
                                </CardHeader>
                                <CardHeader>
                                  <ContentDetails>{DateTime.fromSeconds(parseInt(data.memory_date)).toLocaleString(
                                    DateTime.DATE_MED
                                  )}</ContentDetails>
                                </CardHeader>
                              </CheckContent>
                            </CardRow>
                          );
                        })}
                    </CardList>
                  </CardBody>
                  <BookEditorNote>
                    Note: Submitting your memory selection will share these memories with our designer to estimate the length of your book and provide your book layout. We recommend reviewing your selection before submitting to ensure you are comfortable with what will be shared. Additionally, only JPG and PNG images will be added to your book (no PDFs, MP3s, other media types).
                  </BookEditorNote>
                  <CardFooter>
                    {bookId && parseInt(bookId) !== 0 ? 
                    <CardButton
                      minWidth="195px"
                      type="primary"
                      onClick={reSubmitButtonClick}
                      disabled={bookUpdateStatus === false || selectedItem.length <= 0 }
                      >
                      {
                        bookUpdateStatus === false ? (
                        <SpinnerCircular
                          size={24}
                          thickness={200}
                          speed={100}
                          color={`rgba(${theme.colors.white}, 1)`}
                          secondaryColor={`rgba( ${theme.colors.neutral600}, 1)`}
                        />
                        ) : 'Re-submit selection'
                      }
                      
                      </CardButton> :
                    <CardButton
                     minWidth="168px"
                     type="primary"
                     onClick={ submitSelection }
                     disabled={ bookMemoriesAdded === false || selectedItem.length <= 0 }
                     >
                      {
                        bookMemoriesAdded === false ? (
                          <SpinnerCircular
                          size={24}
                          thickness={200}
                          speed={100}
                          color={`rgba(${theme.colors.white}, 1)`}
                          secondaryColor={`rgba( ${theme.colors.neutral600}, 1)`}
                        />
                        ) : 'Submit selection'
                      }
                      </CardButton>
                    }
                    <LogoutButtonWrapper onClickCapture={(e) => logoutButtonWrappedClicked(e)}>
                    <LogoutButton />
                    </LogoutButtonWrapper>
                  </CardFooter>
                </BookCard>
              </MainContainer>
              <ModalPopUp isOpen={isModalOpen}>
              <BookMemoryModal
              closeModal={closeModal}
              reSubmitSelection={reSubmitSelection}
              isLogoutModal={isLogoutModal}
              ></BookMemoryModal>
              </ModalPopUp>
            </BookEditorContainer>

            <ThankYouComponent closeWindow={closeWindow} ref={thankuRef}/>
          </>

      }



    </BookContainer>
  );
};

export default BookEditor;
