import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  RecentPublishedApi,
  AddBookMemories,
  GetBookList,
  GetBookMemories,
  UpdateBookMemories
} from '../../app-api/api';
import { GetMemories } from '../../app-api/newApi';
import { recentObject, recentResponse, bookResponse, generalResponse } from '../../util/Format';
import { getDomain } from '../../util/functions';
import Cookies from 'js-cookie';


export const fetchBookMemories = createAsyncThunk(
  'memory/recentPublishedApis',
  async (obj) => {
    const data = process.env.REACT_APP_NEW_API === 'TRUE' ? obj : recentObject(obj);
    const response = process.env.REACT_APP_NEW_API === 'TRUE' ? await GetMemories(data) : await RecentPublishedApi(data);
    const result = process.env.REACT_APP_NEW_API === 'TRUE' ? response : recentResponse(response);
    return result;
  }
);

export const fetchCurrentUserBookMemories = createAsyncThunk(
    'memory/recentPublishedApi',
    async (obj) => {
      const data = process.env.REACT_APP_NEW_API === 'TRUE' ? obj : recentObject(obj);
      const response = process.env.REACT_APP_NEW_API === 'TRUE' ? await GetMemories(data) : await RecentPublishedApi(data);
      const result = process.env.REACT_APP_NEW_API === 'TRUE' ? response : recentResponse(response);
      return result;
    }
);

export const addBookMemories = createAsyncThunk(
  'memory/AddBookMemories',
  async (obj) => {
    const data = obj;
    const response =  await AddBookMemories(data);
    const result = process.env.REACT_APP_NEW_API === 'TRUE' ? response : bookResponse(response);
    return result;
  }
);

export const getBookMemories = createAsyncThunk(
  'memory/getBookMemories',
  async (obj) => {
    const data = obj;
    const response = await GetBookMemories(data);
    const result = generalResponse(response);
    return result;
  }
);

export const updateBookMemories = createAsyncThunk(
  'memory/updateBookMemories',
  async (obj) => {
    const data = obj;
    const response = await UpdateBookMemories(data);
    const result = generalResponse(response);
    return result;
  }
)

export const getBookList = createAsyncThunk(
  'memory/GetBookList',
  async(obj) => {
    const response = await GetBookList(obj);
    const result = process.env.REACT_APP_NEW_API === 'TRUE' ? response : bookResponse(response);
    return result;
  }
);


const initialState = {
  bookMemories:[],
  books:[],
  selectedMemories: [],
  bookUpdateStatus: null,
  bookMemoriesStatus:'',
  bookMemoriesAdded: null,
  page: 0,
  hasMoreData: true,
  hasBooks: null,
  hasMemories: null,
  totalMemories: 0,
  orderBy: 'changed',
  sortBy: 'DESC',
  hasFilterChanged: false,
};
const domain = getDomain();
export const BookMemorySlice = createSlice({
  name: 'bookMemory',
  initialState,
  reducers: {
    reset: () => initialState,
    sortBookMemoriesByAlphabetical: (state) => {
      state.bookMemories.sort((a,b) => (a.title > b.title ? 1 : -1));
    },
    sortBookMemoriesByChronological: (state) => {
      state.bookMemories.sort((a,b) => (a.memory_date - b.memory_date));
    },
    pageCounter: (state) => {
      state.page += 1;
    },
    setBookMemoriesState: (state, action) => {
      state.bookMemories.length = 0;
      state.page = 0;
      state.hasMoreData = true;
      state.orderBy = action?.payload?.orderBy;
      state.sortBy = action?.payload?.sortBy;
      state.hasFilterChanged = true;
      state.selectedMemories.length = action.payload.selectedMemoriesFlag ? state.selectedMemories.length : 0; 
    }
  },
  extraReducers: {
    [fetchBookMemories.pending]: (state) => {
      state.bookMemoriesStatus = 'loading';
      state.hasFilterChanged = true;
    },
    [fetchBookMemories.fulfilled]: (state, action) => {
      state.bookMemoriesStatus = 'idle';
      state.hasFilterChanged = false;
      if (action?.payload && action?.payload?.code && action?.payload?.code === 200) {
        state.bookMemories.length = 0;
        state.bookMemories = [
          ...state?.bookMemories,
          ...(action?.payload?.data ? action?.payload?.data : []),
        ];
        state.totalMemories = action?.payload?.page?.totalItems;
        state.hasMoreData = action?.payload?.data?.length === 0 ? false : true;
      } else {
        Cookies.remove('uid', {path: '/', domain: domain});
        Cookies.remove('idToken', {path: '/', domain: domain});
      }
    },
    [fetchBookMemories.rejected]: (state, action) => {
      state.bookMemoriesStatus = 'failed';
      Cookies.remove('uid', {path: '/', domain: domain});
      Cookies.remove('idToken', {path: '/', domain: domain});
    },
    [fetchCurrentUserBookMemories.pending]: (state) => {
        state.status = 'loading';
      },
    [fetchCurrentUserBookMemories.fulfilled]: (state, action) => {
    state.status = 'idle';
    if (action?.payload && action?.payload?.code && action?.payload?.code === 200) {
        state.bookMemories = state.hasFilterChanged !== true ? [
        ...state?.bookMemories,
        ...(action?.payload?.data ? action?.payload?.data : []),
        ]: [];
        if (action?.payload?.data?.length === 0) {
            state.hasMoreData = false
        }
    } else {
      Cookies.remove('uid', {path: '/', domain: domain});
      Cookies.remove('idToken', {path: '/', domain: domain});
    }
    },
    [fetchCurrentUserBookMemories.rejected]: (state, action) => {
    state.status = 'failed';
    state.hasMoreData = false;
    localStorage.clear();
    window.location.reload();
    state.error = action.payload;
    },
    [addBookMemories.pending]: (state) => {
      state.hasBooks = null;
      state.bookMemoriesAdded = false;
    },
    [addBookMemories.fulfilled]: (state, action) => {
      if (action?.payload && action?.payload?.code && action?.payload?.code === 200) {
        state.bookMemoriesAdded = true;
      }
      else {
        state.bookMemoriesAdded = false;
      }
    },
    [addBookMemories.rejected]: (state) => {
      state.hasBooks = null;
      state.bookMemoriesAdded = false;
    },
    [getBookList.pending]: (state) => {
      state.hasBooks = null;
      state.bookUpdateStatus = null;
    },
    [getBookList.fulfilled]: (state, action) => {
      if (action?.payload && action?.payload?.code && action?.payload?.code === 200) {
        state.hasBooks = action?.payload?.totalItems > 0 ? true : false;
        state.books = action?.payload?.data;
        state.hasMemories = null;
      }
      else {
        Cookies.remove('uid', { domain: domain });
        Cookies.remove('idToken', { domain: domain });
      }
    },
    [getBookList.rejected]: (state) => {
      state.hasBooks = null;
      state.books = [];
    },
    [getBookMemories.pending]: (state) => {
      state.hasMemories = null;
    },
    [getBookMemories.fulfilled]: (state, action) => {
      if (action?.payload && action?.payload?.code && action?.payload?.code === 200) {
        state.selectedMemories.length = 0;
        state.selectedMemories = [
          ...state?.selectedMemories,
          ...(action?.payload?.data ? action?.payload?.data.map((item) => item.nid) : []),
          ];
          state.hasMemories = state?.selectedMemories?.length ? true : false;
      }
      else {
        Cookies.remove('uid', {path: '/', domain: domain});
        Cookies.remove('idToken', {path: '/', domain: domain});
      }
    },
    [getBookMemories.rejected]: (state) => {
      state.hasMemories = null;
    },
    [updateBookMemories.pending]: (state) => {
      state.bookUpdateStatus = false;
    },
    [updateBookMemories.fulfilled]: (state, action) => {
      if (action?.payload && action?.payload?.code && action?.payload?.code === 200) {
        state.bookUpdateStatus = true;
      }
      else {
        Cookies.remove('uid', {path: '/', domain: domain});
        Cookies.remove('idToken', {path: '/', domain: domain});
      }
    },
    [updateBookMemories.rejected]: (state) => {
      state.bookUpdateStatus = false;
    },
  
  },
});

export const {
  sortBookMemoriesByAlphabetical,
  sortBookMemoriesByChronological,
  pageCounter,
  setBookMemoriesState,
  reset,
} = BookMemorySlice.actions;

export const selectBookMemories = (state) => {
  return state?.bookMemory?.bookMemories;
}
export const selectBookMemoriesStatus = (state) => state?.bookMemory?.bookMemoriesStatus;
export const selectPage = (state) => state?.bookMemory?.page;
export const selectOrderBy = (state) => state?.bookMemory?.orderBy;
export const selectSortBy = (state) => state?.bookMemory?.sortBy;
export const selectHasMoreData = (state) => state?.bookMemory?.hasMoreData;
export const selectHasBooks = (state) => state?.bookMemory?.hasBooks;
export const selectTotalMemories = (state) => state?.bookMemory.totalMemories;
export const selectSelectedMemories = (state) => state?.bookMemory.selectedMemories;
export const selectBookUpdateStatus = (state) => state?.bookMemory?.bookUpdateStatus;
export const selectBookMemoriesAdded = (state) => state?.bookMemory?.bookMemoriesAdded;
export const selectHasBookMemories = (state) => state?.bookMemory?.hasMemories;
export const selectBooks = (state) => state.bookMemory.books;
export default BookMemorySlice.reducer;