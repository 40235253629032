import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from '../../components/Button';
import theme from '../../styles/colors';
import BooKImage from "./components/BookImage";
import { SpinnerCircular } from 'spinners-react';
import {
    Bold,
    Caption1,
    Footnote,
    Headline,
    Subhead,
    Title1,
} from '../../styles/typography';
import { DateTime } from 'luxon';
import LogoutButton from "../user/LogoutButton";
import { useSelector, useDispatch } from "react-redux";
import { 
  selectBooks, 
  getBookList, 
  selectHasBooks, 
  selectBookUpdateStatus,
  setBookMemoriesState,
 } from "../../app/reducers/BookMemorySlice";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CardBody from "./components/CardBodyCss";
import SampleBook from "./components/SampleBook";

const BookSubmissionContainer = styled.div`
display: flex;
flex-direction: column;
`;

const MainContainer = styled.div`
@media only screen and (max-width: 600px) {
    box-sizing: border-box;
} 
@media only screen and (min-width:320px) and (max-width: 599px) {
    box-sizing: border-box;
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
    width: 552px;
    align-self: center;
} 
@media only screen and (min-width: 1000px) {
    padding-left: 224px;
    padding-right: 224px;
    width: 752px;
    align-self: center;
}
`;

const BookCard = styled.div`
display: flex;
flex-direction: column;
gap: 32px;
`;

const CardHeader = styled.div`
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;
  @media screen and (max-width:599px){
    text-align: start;
    padding: 0px 24px;
  }
`;

const CardTitle = styled(Title1)`
  color: ${theme.buttonPrimaryBg};
  font-size: 30px;
  line-height: 125%;
`;

const CardDescription = styled(Headline)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;

const CardList = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
`;

const CardRow = styled.div`
cursor: pointer;
`;

const CheckContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
//   gap: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(7, 53, 98, 0.05),
    0px 2px 2px rgba(7, 53, 98, 0.05);
  border-radius: 8px;
`;

const ContentTitle = styled(Subhead)`
  color: ${`rgb(${theme.colors.neutral600})`};
  word-break: break-word;
`;

const ContentDetails = styled(Caption1)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;

const StatusText = styled(Footnote)`
align-self: center;
`;

const CardFooter = styled.div` 
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 12px;
padding-bottom: 24px;
@media only screen and (max-width: 599px) {
  flex-direction: column;
  justify-content: center;
}
`;
const CardButton = styled(Button)`
@media only screen and (min-width: 320px) and (max-width: 599px){
  width: 90%;
}
`;
const StyledBr = styled.br``;

const BookSubmission = () => {
  const uid = Cookies.get('uid');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const books = useSelector(selectBooks);
  const hasBooks = useSelector(selectHasBooks);
  const bookUpdateStatus = useSelector(selectBookUpdateStatus);
  useEffect(() => {
    if (books?.length === 0 || bookUpdateStatus === true) {
      dispatch(getBookList({ details: { uid: uid } }));
    }
    const order = {
      orderBy:  'changed',
      sortBy: 'DESC',
    }
    dispatch(setBookMemoriesState(order));
  }, [dispatch, bookUpdateStatus]);

    return (
        <BookSubmissionContainer>
            <BooKImage />
            <MainContainer>
                <BookCard>
                  <CardHeader>
                    <CardTitle>Your books</CardTitle>
                    <CardDescription>
                    Click to manage your books below. You’ll only be able to edit those that are “Submitted.”
                    </CardDescription>
                    <CardDescription>
                    <StyledBr/>    
                    {'In the meantime, you can view our sample to get an idea of what your book could look like! '}
                    <SampleBook />
                    </CardDescription>
                  </CardHeader>

                  <CardBody
                    padding="24px 24px 24px 24px"
                    className="test_scroll"
                    id="overflowMain"
                  >
                    {((hasBooks === null))  ?
                      <>
                        <SpinnerCircular
                                        size={48}
                                        thickness={200}
                                        aria-label="Loading Spinner"
                                        speed={100}
                                        color={`rgba(${theme.colors.neutral200}, 1)`}
                                        secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
                                        style={{ justifySelf: 'center', position: 'relative', left: '45%' }}
                                      />
                      </> : (hasBooks === true) ?
                        <CardList>
                          {books &&
                            books?.map((data, ind) => {
                              return (
                                <CardRow key={Math.random() + ind} onClick={() => {
                                  if (parseInt(data.book_status) ===1) navigate(`/app/bookedit?bookId=${data.id}`);
                                  //navigate(`/app/bookedit?bookId=${data.id}`)
                                }}
                                style={{cursor:parseInt(data.book_status) === 1 ? 'pointer' : 'auto'}}
                                >
                                    <CheckContent style={
                                      {background: parseInt(data.book_status) === 1 ? '#ffffff' : 'inherit',
                                       boxShadow: parseInt(data.book_status) === 1 ? '0px 1px 1px rgba(7, 53, 98, 0.05), 0px 2px 2px rgba(7, 53, 98, 0.05)' : 'none' }}>
                                        <CardHeader>
                                        <ContentTitle>{DateTime.fromSeconds(parseInt(data.changed)).toLocaleString(
                                            DateTime.DATE_MED
                                        )}</ContentTitle>
                                        <ContentDetails>{data.memory_count} memories</ContentDetails>
                                        </CardHeader>
                                        <StatusText>
                                            {data.status_text}
                                        </StatusText>
                                    </CheckContent>
                                </CardRow>
                              );
                            })}
                        </CardList> : 'No books found'
                    }

                  </CardBody>
                  
                  <CardFooter>
                    <CardButton
                      type="primary"
                      onClick={() => navigate(`/app/bookedit?bookId=0`)}
                    >
                      + New Book
                    </CardButton>
                    <LogoutButton/>
                  </CardFooter>
                </BookCard>
              </MainContainer>
        </BookSubmissionContainer>
    );
};

export default BookSubmission;